
  $(document).ready(function(){
    
    
$('.q1 .b1').click(function(){swb1();Audio2();$('.q1 .b1').addClass('wbtr');
$('.q1 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q1 .b2,.q1 .b3').removeClass('wbtr');});
$('.q1 .b2').click(function(){swb2();Audio2();$('.q1 .b2').addClass('wbtr');
$('.q1 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q1 .b1,.q1 .b3').removeClass('wbtr');});
$('.q1 .b3').click(function(){swb3();Audio2();$('.q1 .b3').addClass('wbtr');
$('.q1 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q1 .b2,.q1 .b1').removeClass('wbtr');});
$('.q1 .c1').click(function(){scb1();Audio1();$('.q1 ww').hide();$('.q1 ch').fadeIn();
$('.q1 cm').slideDown('fast').delay(900).fadeOut();});

$('.q2 .b1').click(function(){swb4();Audio2();$('.q2 .b1').addClass('wbtr');
$('.q2 wm').finish().hide().slideDown('fast').delay(800).fadeOut();$('.q2 .b2,.q2 .b3').removeClass('wbtr');});
$('.q2 .b2').click(function(){swb5();Audio2();$('.q2 .b2').addClass('wbtr');
$('.q2 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q2 .b1,.q2 .b3').removeClass('wbtr');});
$('.q2 .b3').click(function(){swb6();Audio2();$('.q2 .b3').addClass('wbtr');
$('.q2 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q2 .b2,.q2 .b1').removeClass('wbtr');});
$('.q2 .c1').click(function(){scb2();Audio1();$('.q2 ww').hide();$('.q2 ch').fadeIn();
$('.q2 cm').slideDown('fast').delay(900).fadeOut();});
    
$('.q3 .b1').click(function(){swb7();Audio2();$('.q3 .b1').addClass('wbtr');
$('.q3 wm').finish().hide().slideDown('fast').delay(800).fadeOut();$('.q3 .b2,.q3 .b3').removeClass('wbtr');});
$('.q3 .b2').click(function(){swb8();Audio2();$('.q3 .b2').addClass('wbtr');
$('.q3 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q3 .b1,.q3 .b3').removeClass('wbtr');});
$('.q3 .b3').click(function(){swb9();Audio2();$('.q3 .b3').addClass('wbtr');
$('.q3 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q3 .b2,.q3 .b1').removeClass('wbtr');});
$('.q3 .c1').click(function(){scb3();Audio1();$('.q3 ww').hide();$('.q3 ch').fadeIn();
$('.q3 cm').slideDown('fast').delay(900).fadeOut();});
    
$('.q4 .b1').click(function(){swb10();Audio2();$('.q4 .b1').addClass('wbtr');
$('.q4 wm').finish().hide().slideDown('fast').delay(800).fadeOut();$('.q4 .b2,.q4 .b3').removeClass('wbtr');});
$('.q4 .b2').click(function(){swb11();Audio2();$('.q4 .b2').addClass('wbtr');
$('.q4 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q4 .b1,.q4 .b3').removeClass('wbtr');});
$('.q4 .b3').click(function(){swb12();Audio2();$('.q4 .b3').addClass('wbtr');
$('.q4 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q4 .b2,.q4 .b1').removeClass('wbtr');});
$('.q4 .c1').click(function(){scb4();Audio1();$('.q4 ww').hide();$('.q4 ch').fadeIn();
$('.q4 cm').slideDown('fast').delay(900).fadeOut();});

$('.q5 .b1').click(function(){swb13();Audio2();$('.q5 .b1').addClass('wbtr');
$('.q5 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q5 .b2,.q5 .b3').removeClass('wbtr');});
$('.q5 .b2').click(function(){swb14();Audio2();$('.q5 .b2').addClass('wbtr');
$('.q5 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q5 .b1,.q5 .b3').removeClass('wbtr');});
$('.q5 .b3').click(function(){swb15();Audio2();$('.q5 .b3').addClass('wbtr');
$('.q5 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q5 .b2,.q5 .b1').removeClass('wbtr');});
$('.q5 .c1').click(function(){scb5();Audio1();$('.q5 ww').hide();$('.q5 ch').fadeIn();
$('.q5 cm').slideDown('fast').delay(900).fadeOut();});
    
$('.q6 .b1').click(function(){swb16();Audio2();$('.q6 .b1').addClass('wbtr');
$('.q6 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q6 .b2,.q6 .b3').removeClass('wbtr');});
$('.q6 .b2').click(function(){swb17();Audio2();$('.q6 .b2').addClass('wbtr');
$('.q6 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q6 .b1,.q6 .b3').removeClass('wbtr');});
$('.q6 .b3').click(function(){swb18();Audio2();$('.q6 .b3').addClass('wbtr');
$('.q6 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q6 .b2,.q6 .b1').removeClass('wbtr');});
$('.q6 .c1').click(function(){scb6();Audio1();$('.q6 ww').hide();$('.q6 ch').fadeIn();
$('.q6 cm').slideDown('fast').delay(900).fadeOut();});
    
$('.q7 .b1').click(function(){swb19();Audio2();$('.q7 .b1').addClass('wbtr');
$('.q7 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q7 .b2,.q7 .b3').removeClass('wbtr');});
$('.q7 .b2').click(function(){swb20();Audio2();$('.q7 .b2').addClass('wbtr');
$('.q7 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q7 .b1,.q7 .b3').removeClass('wbtr');});
$('.q7 .b3').click(function(){swb21();Audio2();$('.q7 .b3').addClass('wbtr');
$('.q7 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q7 .b2,.q7 .b1').removeClass('wbtr');});
$('.q7 .c1').click(function(){scb7();Audio1();$('.q7 ww').hide();$('.q7 ch').fadeIn();
$('.q7 cm').slideDown('fast').delay(900).fadeOut();});
    
$('.q8 .b1').click(function(){swb22();Audio2();$('.q8 .b1').addClass('wbtr');
$('.q8 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q8 .b2,.q8 .b3').removeClass('wbtr');});
$('.q8 .b2').click(function(){swb23();Audio2();$('.q8 .b2').addClass('wbtr');
$('.q8 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q8 .b1,.q8 .b3').removeClass('wbtr');});
$('.q8 .b3').click(function(){swb24();Audio2();$('.q8 .b3').addClass('wbtr');
$('.q8 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q8 .b2,.q8 .b1').removeClass('wbtr');});
$('.q8 .c1').click(function(){scb8();Audio1();$('.q8 ww').hide();$('.q8 ch').fadeIn();
$('.q8 cm').slideDown('fast').delay(900).fadeOut();});
  
$('.q9 .b1').click(function(){swb25();Audio2();$('.q9 .b1').addClass('wbtr');
$('.q9 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q9 .b2,.q9 .b3').removeClass('wbtr');});
$('.q9 .b2').click(function(){swb26();Audio2();$('.q9 .b2').addClass('wbtr');
$('.q9 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q9 .b1,.q9 .b3').removeClass('wbtr');});
$('.q9 .b3').click(function(){swb27();Audio2();$('.q9 .b3').addClass('wbtr');
$('.q9 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q9 .b2,.q9 .b1').removeClass('wbtr');});
$('.q9 .c1').click(function(){scb9();Audio1();$('.q9 ww').hide();$('.q9 ch').fadeIn();
$('.q9 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q10 .b1').click(function(){swb28();Audio2();$('.q10 .b1').addClass('wbtr');
$('.q10 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q10 .b2,.q10 .b3').removeClass('wbtr');});
$('.q10 .b2').click(function(){swb29();Audio2();$('.q10 .b2').addClass('wbtr');
$('.q10 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q10 .b1,.q10 .b3').removeClass('wbtr');});
$('.q10 .b3').click(function(){swb30();Audio2();$('.q10 .b3').addClass('wbtr');
$('.q10 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q10 .b2,.q10 .b1').removeClass('wbtr');});
$('.q10 .c1').click(function(){scb10();Audio1();$('.q10 ww').hide();$('.q10 ch').fadeIn();
$('.q10 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q11 .b1').click(function(){swb31();Audio2();$('.q11 .b1').addClass('wbtr');
$('.q11 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q11 .b2,.q11 .b3').removeClass('wbtr');});
$('.q11 .b2').click(function(){swb32();Audio2();$('.q11 .b2').addClass('wbtr');
$('.q11 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q11 .b1,.q11 .b3').removeClass('wbtr');});
$('.q11 .b3').click(function(){swb33();Audio2();$('.q11 .b3').addClass('wbtr');
$('.q11 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q11 .b2,.q11 .b1').removeClass('wbtr');});
$('.q11 .c1').click(function(){scb11();Audio1();$('.q11 ww').hide();$('.q11 ch').fadeIn();
$('.q11 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q12 .b1').click(function(){swb34();Audio2();$('.q12 .b1').addClass('wbtr');
$('.q12 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q12 .b2,.q12 .b3').removeClass('wbtr');});
$('.q12 .b2').click(function(){swb35();Audio2();$('.q12 .b2').addClass('wbtr');
$('.q12 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q12 .b1,.q12 .b3').removeClass('wbtr');});
$('.q12 .b3').click(function(){swb36();Audio2();$('.q12 .b3').addClass('wbtr');
$('.q12 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q12 .b2,.q12 .b1').removeClass('wbtr');});
$('.q12 .c1').click(function(){scb12();Audio1();$('.q12 ww').hide();$('.q12 ch').fadeIn();
$('.q12 cm').slideDown('fast').delay(900).fadeOut();});   
    
$('.q13 .b1').click(function(){swb37();Audio2();$('.q13 .b1').addClass('wbtr');
$('.q13 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q13 .b2,.q13 .b3').removeClass('wbtr');});
$('.q13 .b2').click(function(){swb38();Audio2();$('.q13 .b2').addClass('wbtr');
$('.q13 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q13 .b1,.q13 .b3').removeClass('wbtr');});
$('.q13 .b3').click(function(){swb39();Audio2();$('.q13 .b3').addClass('wbtr');
$('.q13 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q13 .b2,.q13 .b1').removeClass('wbtr');});
$('.q13 .c1').click(function(){scb13();Audio1();$('.q13 ww').hide();$('.q13 ch').fadeIn();
$('.q13 cm').slideDown('fast').delay(900).fadeOut();});
  
$('.q14 .b1').click(function(){swb40();Audio2();$('.q14 .b1').addClass('wbtr');
$('.q14 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q14 .b2,.q14 .b3').removeClass('wbtr');});
$('.q14 .b2').click(function(){swb41();Audio2();$('.q14 .b2').addClass('wbtr');
$('.q14 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q14 .b1,.q14 .b3').removeClass('wbtr');});
$('.q14 .b3').click(function(){swb42();Audio2();$('.q14 .b3').addClass('wbtr');
$('.q14 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q14 .b2,.q14 .b1').removeClass('wbtr');});
$('.q14 .c1').click(function(){scb14();Audio1();$('.q14 ww').hide();$('.q14 ch').fadeIn();
$('.q14 cm').slideDown('fast').delay(900).fadeOut();});   
    
$('.q15 .b1').click(function(){swb43();Audio2();$('.q15 .b1').addClass('wbtr');
$('.q15 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q15 .b2,.q15 .b3').removeClass('wbtr');});
$('.q15 .b2').click(function(){swb44();Audio2();$('.q15 .b2').addClass('wbtr');
$('.q15 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q15 .b1,.q15 .b3').removeClass('wbtr');});
$('.q15 .b3').click(function(){swb45();Audio2();$('.q15 .b3').addClass('wbtr');
$('.q15 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q15 .b2,.q15 .b1').removeClass('wbtr');});
$('.q15 .c1').click(function(){scb15();Audio1();$('.q15 ww').hide();$('.q15 ch').fadeIn();
$('.q15 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q16 .b1').click(function(){swb46();Audio2();$('.q16 .b1').addClass('wbtr');
$('.q16 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q16 .b2,.q16 .b3').removeClass('wbtr');});
$('.q16 .b2').click(function(){swb47();Audio2();$('.q16 .b2').addClass('wbtr');
$('.q16 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q16 .b1,.q16 .b3').removeClass('wbtr');});
$('.q16 .b3').click(function(){swb48();Audio2();$('.q16 .b3').addClass('wbtr');
$('.q16 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q16 .b2,.q16 .b1').removeClass('wbtr');});
$('.q16 .c1').click(function(){scb16();Audio1();$('.q16 ww').hide();$('.q16 ch').fadeIn();
$('.q16 cm').slideDown('fast').delay(900).fadeOut();});
    
$('.q17 .b1').click(function(){swb49();Audio2();$('.q17 .b1').addClass('wbtr');
$('.q17 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q17 .b2,.q17 .b3').removeClass('wbtr');});
$('.q17 .b2').click(function(){swb50();Audio2();$('.q17 .b2').addClass('wbtr');
$('.q17 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q17 .b1,.q17 .b3').removeClass('wbtr');});
$('.q17 .b3').click(function(){swb51();Audio2();$('.q17 .b3').addClass('wbtr');
$('.q17 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q17 .b2,.q17 .b1').removeClass('wbtr');});
$('.q17 .c1').click(function(){scb17();Audio1();$('.q17 ww').hide();$('.q17 ch').fadeIn();
$('.q17 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q18 .b1').click(function(){swb52();Audio2();$('.q18 .b1').addClass('wbtr');
$('.q18 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q18 .b2,.q18 .b3').removeClass('wbtr');});
$('.q18 .b2').click(function(){swb53();Audio2();$('.q18 .b2').addClass('wbtr');
$('.q18 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q18 .b1,.q18 .b3').removeClass('wbtr');});
$('.q18 .b3').click(function(){swb54();Audio2();$('.q18 .b3').addClass('wbtr');
$('.q18 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q18 .b2,.q18 .b1').removeClass('wbtr');});
$('.q18 .c1').click(function(){scb18();Audio1();$('.q18 ww').hide();$('.q18 ch').fadeIn();
$('.q18 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q19 .b1').click(function(){swb55();Audio2();$('.q19 .b1').addClass('wbtr');
$('.q19 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q19 .b2,.q19 .b3').removeClass('wbtr');});
$('.q19 .b2').click(function(){swb56();Audio2();$('.q19 .b2').addClass('wbtr');
$('.q19 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q19 .b1,.q19 .b3').removeClass('wbtr');});
$('.q19 .b3').click(function(){swb57();Audio2();$('.q19 .b3').addClass('wbtr');
$('.q19 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q19 .b2,.q19 .b1').removeClass('wbtr');});
$('.q19 .c1').click(function(){scb19();Audio1();$('.q19 ww').hide();$('.q19 ch').fadeIn();
$('.q19 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q20 .b1').click(function(){swb58();Audio2();$('.q20 .b1').addClass('wbtr');
$('.q20 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q20 .b2,.q20 .b3').removeClass('wbtr');});
$('.q20 .b2').click(function(){swb59();Audio2();$('.q20 .b2').addClass('wbtr');
$('.q20 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q20 .b1,.q20 .b3').removeClass('wbtr');});
$('.q20 .b3').click(function(){swb60();Audio2();$('.q20 .b3').addClass('wbtr');
$('.q20 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q20 .b2,.q20 .b1').removeClass('wbtr');});
$('.q20 .c1').click(function(){scb20();Audio1();$('.q20 ww').hide();$('.q20 ch').fadeIn();
$('.q20 cm').slideDown('fast').delay(900).fadeOut();});   
    
$('.q21 .b1').click(function(){swb61();Audio2();$('.q21 .b1').addClass('wbtr');
$('.q21 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q21 .b2,.q21 .b3').removeClass('wbtr');});
$('.q21 .b2').click(function(){swb62();Audio2();$('.q21 .b2').addClass('wbtr');
$('.q21 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q21 .b1,.q21 .b3').removeClass('wbtr');});
$('.q21 .b3').click(function(){swb63();Audio2();$('.q21 .b3').addClass('wbtr');
$('.q21 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q21 .b2,.q21 .b1').removeClass('wbtr');});
$('.q21 .c1').click(function(){scb21();Audio1();$('.q21 ww').hide();$('.q21 ch').fadeIn();
$('.q21 cm').slideDown('fast').delay(900).fadeOut();});
    
$('.q22 .b1').click(function(){swb64();Audio2();$('.q22 .b1').addClass('wbtr');
$('.q22 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q22 .b2,.q22 .b3').removeClass('wbtr');});
$('.q22 .b2').click(function(){swb65();Audio2();$('.q22 .b2').addClass('wbtr');
$('.q22 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q22 .b1,.q22 .b3').removeClass('wbtr');});
$('.q22 .b3').click(function(){swb66();Audio2();$('.q22 .b3').addClass('wbtr');
$('.q22 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q22 .b2,.q22 .b1').removeClass('wbtr');});
$('.q22 .c1').click(function(){scb22();Audio1();$('.q22 ww').hide();$('.q22 ch').fadeIn();
$('.q22 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q23 .b1').click(function(){swb67();Audio2();$('.q23 .b1').addClass('wbtr');
$('.q23 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q23 .b2,.q23 .b3').removeClass('wbtr');});
$('.q23 .b2').click(function(){swb68();Audio2();$('.q23 .b2').addClass('wbtr');
$('.q23 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q23 .b1,.q23 .b3').removeClass('wbtr');});
$('.q23 .b3').click(function(){swb69();Audio2();$('.q23 .b3').addClass('wbtr');
$('.q23 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q23 .b2,.q23 .b1').removeClass('wbtr');});
$('.q23 .c1').click(function(){scb23();Audio1();$('.q23 ww').hide();$('.q23 ch').fadeIn();
$('.q23 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q24 .b1').click(function(){swb70();Audio2();$('.q24 .b1').addClass('wbtr');
$('.q24 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q24 .b2,.q24 .b3').removeClass('wbtr');});
$('.q24 .b2').click(function(){swb71();Audio2();$('.q24 .b2').addClass('wbtr');
$('.q24 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q24 .b1,.q24 .b3').removeClass('wbtr');});
$('.q24 .b3').click(function(){swb72();Audio2();$('.q24 .b3').addClass('wbtr');
$('.q24 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q24 .b2,.q24 .b1').removeClass('wbtr');});
$('.q24 .c1').click(function(){scb24();Audio1();$('.q24 ww').hide();$('.q24 ch').fadeIn();
$('.q24 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q25 .b1').click(function(){swb73();Audio2();$('.q25 .b1').addClass('wbtr');
$('.q25 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q25 .b2,.q25 .b3').removeClass('wbtr');});
$('.q25 .b2').click(function(){swb74();Audio2();$('.q25 .b2').addClass('wbtr');
$('.q25 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q25 .b1,.q25 .b3').removeClass('wbtr');});
$('.q25 .b3').click(function(){swb75();Audio2();$('.q25 .b3').addClass('wbtr');
$('.q25 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q25 .b2,.q25 .b1').removeClass('wbtr');});
$('.q25 .c1').click(function(){scb25();Audio1();$('.q25 ww').hide();$('.q25 ch').fadeIn();
$('.q25 cm').slideDown('fast').delay(900).fadeOut();});       
    
$('.q26 .b1').click(function(){swb76();Audio2();$('.q26 .b1').addClass('wbtr');
$('.q26 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q26 .b2,.q26 .b3').removeClass('wbtr');});
$('.q26 .b2').click(function(){swb77();Audio2();$('.q26 .b2').addClass('wbtr');
$('.q26 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q26 .b1,.q26 .b3').removeClass('wbtr');});
$('.q26 .b3').click(function(){swb78();Audio2();$('.q26 .b3').addClass('wbtr');
$('.q26 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q26 .b2,.q26 .b1').removeClass('wbtr');});
$('.q26 .c1').click(function(){scb26();Audio1();$('.q26 ww').hide();$('.q26 ch').fadeIn();
$('.q26 cm').slideDown('fast').delay(900).fadeOut();});   
    
$('.q27 .b1').click(function(){swb79();Audio2();$('.q27 .b1').addClass('wbtr');
$('.q27 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q27 .b2,.q27 .b3').removeClass('wbtr');});
$('.q27 .b2').click(function(){swb80();Audio2();$('.q27 .b2').addClass('wbtr');
$('.q27 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q27 .b1,.q27 .b3').removeClass('wbtr');});
$('.q27 .b3').click(function(){swb81();Audio2();$('.q27 .b3').addClass('wbtr');
$('.q27 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q27 .b2,.q27 .b1').removeClass('wbtr');});
$('.q27 .c1').click(function(){scb27();Audio1();$('.q27 ww').hide();$('.q27 ch').fadeIn();
$('.q27 cm').slideDown('fast').delay(900).fadeOut();});   
    
$('.q28 .b1').click(function(){swb82();Audio2();$('.q28 .b1').addClass('wbtr');
$('.q28 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q28 .b2,.q28 .b3').removeClass('wbtr');});
$('.q28 .b2').click(function(){swb83();Audio2();$('.q28 .b2').addClass('wbtr');
$('.q28 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q28 .b1,.q28 .b3').removeClass('wbtr');});
$('.q28 .b3').click(function(){swb84();Audio2();$('.q28 .b3').addClass('wbtr');
$('.q28 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q28 .b2,.q28 .b1').removeClass('wbtr');});
$('.q28 .c1').click(function(){scb28();Audio1();$('.q28 ww').hide();$('.q28 ch').fadeIn();
$('.q28 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q29 .b1').click(function(){swb85();Audio2();$('.q29 .b1').addClass('wbtr');
$('.q29 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q29 .b2,.q29 .b3').removeClass('wbtr');});
$('.q29 .b2').click(function(){swb86();Audio2();$('.q29 .b2').addClass('wbtr');
$('.q29 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q29 .b1,.q29 .b3').removeClass('wbtr');});
$('.q29 .b3').click(function(){swb87();Audio2();$('.q29 .b3').addClass('wbtr');
$('.q29 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q29 .b2,.q29 .b1').removeClass('wbtr');});
$('.q29 .c1').click(function(){scb29();Audio1();$('.q29 ww').hide();$('.q29 ch').fadeIn();
$('.q29 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q30 .b1').click(function(){swb88();Audio2();$('.q30 .b1').addClass('wbtr');
$('.q30 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q30 .b2,.q30 .b3').removeClass('wbtr');});
$('.q30 .b2').click(function(){swb89();Audio2();$('.q30 .b2').addClass('wbtr');
$('.q30 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q30 .b1,.q30 .b3').removeClass('wbtr');});
$('.q30 .b3').click(function(){swb90();Audio2();$('.q30 .b3').addClass('wbtr');
$('.q30 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q30 .b2,.q30 .b1').removeClass('wbtr');});
$('.q30 .c1').click(function(){scb30();Audio1();$('.q30 ww').hide();$('.q30 ch').fadeIn();
$('.q30 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q31 .b1').click(function(){swb91();Audio2();$('.q31 .b1').addClass('wbtr');
$('.q31 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q31 .b2,.q31 .b3').removeClass('wbtr');});
$('.q31 .b2').click(function(){swb92();Audio2();$('.q31 .b2').addClass('wbtr');
$('.q31 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q31 .b1,.q31 .b3').removeClass('wbtr');});
$('.q31 .b3').click(function(){swb93();Audio2();$('.q31 .b3').addClass('wbtr');
$('.q31 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q31 .b2,.q31 .b1').removeClass('wbtr');});
$('.q31 .c1').click(function(){scb31();Audio1();$('.q31 ww').hide();$('.q31 ch').fadeIn();
$('.q31 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q32 .b1').click(function(){swb94();Audio2();$('.q32 .b1').addClass('wbtr');
$('.q32 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q32 .b2,.q32 .b3').removeClass('wbtr');});
$('.q32 .b2').click(function(){swb95();Audio2();$('.q32 .b2').addClass('wbtr');
$('.q32 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q32 .b1,.q32 .b3').removeClass('wbtr');});
$('.q32 .b3').click(function(){swb96();Audio2();$('.q32 .b3').addClass('wbtr');
$('.q32 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q32 .b2,.q32 .b1').removeClass('wbtr');});
$('.q32 .c1').click(function(){scb32();Audio1();$('.q32 ww').hide();$('.q32 ch').fadeIn();
$('.q32 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q33 .b1').click(function(){swb97();Audio2();$('.q33 .b1').addClass('wbtr');
$('.q33 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q33 .b2,.q33 .b3').removeClass('wbtr');});
$('.q33 .b2').click(function(){swb98();Audio2();$('.q33 .b2').addClass('wbtr');
$('.q33 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q33 .b1,.q33 .b3').removeClass('wbtr');});
$('.q33 .b3').click(function(){swb99();Audio2();$('.q33 .b3').addClass('wbtr');
$('.q33 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q33 .b2,.q33 .b1').removeClass('wbtr');});
$('.q33 .c1').click(function(){scb33();Audio1();$('.q33 ww').hide();$('.q33 ch').fadeIn();
$('.q33 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q34 .b1').click(function(){swb100();Audio2();$('.q34 .b1').addClass('wbtr');
$('.q34 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q34 .b2,.q34 .b3').removeClass('wbtr');});
$('.q34 .b2').click(function(){swb101();Audio2();$('.q34 .b2').addClass('wbtr');
$('.q34 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q34 .b1,.q34 .b3').removeClass('wbtr');});
$('.q34 .b3').click(function(){swb102();Audio2();$('.q34 .b3').addClass('wbtr');
$('.q34 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q34 .b2,.q34 .b1').removeClass('wbtr');});
$('.q34 .c1').click(function(){scb34();Audio1();$('.q34 ww').hide();$('.q34 ch').fadeIn();
$('.q34 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q35 .b1').click(function(){swb103();Audio2();$('.q35 .b1').addClass('wbtr');
$('.q35 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q35 .b2,.q35 .b3').removeClass('wbtr');});
$('.q35 .b2').click(function(){swb104();Audio2();$('.q35 .b2').addClass('wbtr');
$('.q35 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q35 .b1,.q35 .b3').removeClass('wbtr');});
$('.q35 .b3').click(function(){swb105();Audio2();$('.q35 .b3').addClass('wbtr');
$('.q35 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q35 .b2,.q35 .b1').removeClass('wbtr');});
$('.q35 .c1').click(function(){scb35();Audio1();$('.q35 ww').hide();$('.q35 ch').fadeIn();
$('.q35 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q36 .b1').click(function(){swb106();Audio2();$('.q36 .b1').addClass('wbtr');
$('.q36 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q36 .b2,.q36 .b3').removeClass('wbtr');});
$('.q36 .b2').click(function(){swb107();Audio2();$('.q36 .b2').addClass('wbtr');
$('.q36 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q36 .b1,.q36 .b3').removeClass('wbtr');});
$('.q36 .b3').click(function(){swb108();Audio2();$('.q36 .b3').addClass('wbtr');
$('.q36 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q36 .b2,.q36 .b1').removeClass('wbtr');});
$('.q36 .c1').click(function(){scb36();Audio1();$('.q36 ww').hide();$('.q36 ch').fadeIn();
$('.q36 cm').slideDown('fast').delay(900).fadeOut();});   
    
$('.q37 .b1').click(function(){swb109();Audio2();$('.q37 .b1').addClass('wbtr');
$('.q37 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q37 .b2,.q37 .b3').removeClass('wbtr');});
$('.q37 .b2').click(function(){swb110();Audio2();$('.q37 .b2').addClass('wbtr');
$('.q37 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q37 .b1,.q37 .b3').removeClass('wbtr');});
$('.q37 .b3').click(function(){swb111();Audio2();$('.q37 .b3').addClass('wbtr');
$('.q37 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q37 .b2,.q37 .b1').removeClass('wbtr');});
$('.q37 .c1').click(function(){scb37();Audio1();$('.q37 ww').hide();$('.q37 ch').fadeIn();
$('.q37 cm').slideDown('fast').delay(900).fadeOut();});  
    
$('.q38 .b1').click(function(){swb112();Audio2();$('.q38 .b1').addClass('wbtr');
$('.q38 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q38 .b2,.q38 .b3').removeClass('wbtr');});
$('.q38 .b2').click(function(){swb113();Audio2();$('.q38 .b2').addClass('wbtr');
$('.q38 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q38 .b1,.q38 .b3').removeClass('wbtr');});
$('.q38 .b3').click(function(){swb114();Audio2();$('.q38 .b3').addClass('wbtr');
$('.q38 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q38 .b2,.q38 .b1').removeClass('wbtr');});
$('.q38 .c1').click(function(){scb38();Audio1();$('.q38 ww').hide();$('.q38 ch').fadeIn();
$('.q38 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q39 .b1').click(function(){swb115();Audio2();$('.q39 .b1').addClass('wbtr');
$('.q39 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q39 .b2,.q39 .b3').removeClass('wbtr');});
$('.q39 .b2').click(function(){swb116();Audio2();$('.q39 .b2').addClass('wbtr');
$('.q39 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q39 .b1,.q39 .b3').removeClass('wbtr');});
$('.q39 .b3').click(function(){swb117();Audio2();$('.q39 .b3').addClass('wbtr');
$('.q39 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q39 .b2,.q39 .b1').removeClass('wbtr');});
$('.q39 .c1').click(function(){scb39();Audio1();$('.q39 ww').hide();$('.q39 ch').fadeIn();
$('.q39 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q40 .b1').click(function(){swb118();Audio2();$('.q40 .b1').addClass('wbtr');
$('.q40 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q40 .b2,.q40 .b3').removeClass('wbtr');});
$('.q40 .b2').click(function(){swb119();Audio2();$('.q40 .b2').addClass('wbtr');
$('.q40 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q40 .b1,.q40 .b3').removeClass('wbtr');});
$('.q40 .b3').click(function(){swb120();Audio2();$('.q40 .b3').addClass('wbtr');
$('.q40 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q40 .b2,.q40 .b1').removeClass('wbtr');});
$('.q40 .c1').click(function(){scb40();Audio1();$('.q40 ww').hide();$('.q40 ch').fadeIn();
$('.q40 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q41 .b1').click(function(){swb121();Audio2();$('.q41 .b1').addClass('wbtr');
$('.q41 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q41 .b2,.q41 .b3').removeClass('wbtr');});
$('.q41 .b2').click(function(){swb122();Audio2();$('.q41 .b2').addClass('wbtr');
$('.q41 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q41 .b1,.q41 .b3').removeClass('wbtr');});
$('.q41 .b3').click(function(){swb123();Audio2();$('.q41 .b3').addClass('wbtr');
$('.q41 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q41 .b2,.q41 .b1').removeClass('wbtr');});
$('.q41 .c1').click(function(){scb41();Audio1();$('.q41 ww').hide();$('.q41 ch').fadeIn();
$('.q41 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q42 .b1').click(function(){swb124();Audio2();$('.q42 .b1').addClass('wbtr');
$('.q42 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q42 .b2,.q42 .b3').removeClass('wbtr');});
$('.q42 .b2').click(function(){swb125();Audio2();$('.q42 .b2').addClass('wbtr');
$('.q42 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q42 .b1,.q42 .b3').removeClass('wbtr');});
$('.q42 .b3').click(function(){swb126();Audio2();$('.q42 .b3').addClass('wbtr');
$('.q42 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q42 .b2,.q42 .b1').removeClass('wbtr');});
$('.q42 .c1').click(function(){scb42();Audio1();$('.q42 ww').hide();$('.q42 ch').fadeIn();
$('.q42 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q43 .b1').click(function(){swb127();Audio2();$('.q43 .b1').addClass('wbtr');
$('.q43 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q43 .b2,.q43 .b3').removeClass('wbtr');});
$('.q43 .b2').click(function(){swb128();Audio2();$('.q43 .b2').addClass('wbtr');
$('.q43 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q43 .b1,.q43 .b3').removeClass('wbtr');});
$('.q43 .b3').click(function(){swb129();Audio2();$('.q43 .b3').addClass('wbtr');
$('.q43 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q43 .b2,.q43 .b1').removeClass('wbtr');});
$('.q43 .c1').click(function(){scb43();Audio1();$('.q43 ww').hide();$('.q43 ch').fadeIn();
$('.q43 cm').slideDown('fast').delay(900).fadeOut();});   
    
$('.q44 .b1').click(function(){swb130();Audio2();$('.q44 .b1').addClass('wbtr');
$('.q44 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q44 .b2,.q44 .b3').removeClass('wbtr');});
$('.q44 .b2').click(function(){swb131();Audio2();$('.q44 .b2').addClass('wbtr');
$('.q44 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q44 .b1,.q44 .b3').removeClass('wbtr');});
$('.q44 .b3').click(function(){swb132();Audio2();$('.q44 .b3').addClass('wbtr');
$('.q44 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q44 .b2,.q44 .b1').removeClass('wbtr');});
$('.q44 .c1').click(function(){scb44();Audio1();$('.q44 ww').hide();$('.q44 ch').fadeIn();
$('.q44 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q45 .b1').click(function(){swb133();Audio2();$('.q45 .b1').addClass('wbtr');
$('.q45 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q45 .b2,.q45 .b3').removeClass('wbtr');});
$('.q45 .b2').click(function(){swb134();Audio2();$('.q45 .b2').addClass('wbtr');
$('.q45 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q45 .b1,.q45 .b3').removeClass('wbtr');});
$('.q45 .b3').click(function(){swb135();Audio2();$('.q45 .b3').addClass('wbtr');
$('.q45 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q45 .b2,.q45 .b1').removeClass('wbtr');});
$('.q45 .c1').click(function(){scb45();Audio1();$('.q45 ww').hide();$('.q45 ch').fadeIn();
$('.q45 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q46 .b1').click(function(){swb136();Audio2();$('.q46 .b1').addClass('wbtr');
$('.q46 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q46 .b2,.q46 .b3').removeClass('wbtr');});
$('.q46 .b2').click(function(){swb137();Audio2();$('.q46 .b2').addClass('wbtr');
$('.q46 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q46 .b1,.q46 .b3').removeClass('wbtr');});
$('.q46 .b3').click(function(){swb138();Audio2();$('.q46 .b3').addClass('wbtr');
$('.q46 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q46 .b2,.q46 .b1').removeClass('wbtr');});
$('.q46 .c1').click(function(){scb46();Audio1();$('.q46 ww').hide();$('.q46 ch').fadeIn();
$('.q46 cm').slideDown('fast').delay(900).fadeOut();});    
   
$('.q47 .b1').click(function(){swb139();Audio2();$('.q47 .b1').addClass('wbtr');
$('.q47 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q47 .b2,.q47 .b3').removeClass('wbtr');});
$('.q47 .b2').click(function(){swb140();Audio2();$('.q47 .b2').addClass('wbtr');
$('.q47 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q47 .b1,.q47 .b3').removeClass('wbtr');});
$('.q47 .b3').click(function(){swb141();Audio2();$('.q47 .b3').addClass('wbtr');
$('.q47 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q47 .b2,.q47 .b1').removeClass('wbtr');});
$('.q47 .c1').click(function(){scb47();Audio1();$('.q47 ww').hide();$('.q47 ch').fadeIn();
$('.q47 cm').slideDown('fast').delay(900).fadeOut();});   
    
$('.q48 .b1').click(function(){swb142();Audio2();$('.q48 .b1').addClass('wbtr');
$('.q48 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q48 .b2,.q48 .b3').removeClass('wbtr');});
$('.q48 .b2').click(function(){swb143();Audio2();$('.q48 .b2').addClass('wbtr');
$('.q48 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q48 .b1,.q48 .b3').removeClass('wbtr');});
$('.q48 .b3').click(function(){swb144();Audio2();$('.q48 .b3').addClass('wbtr');
$('.q48 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q48 .b2,.q48 .b1').removeClass('wbtr');});
$('.q48 .c1').click(function(){scb48();Audio1();$('.q48 ww').hide();$('.q48 ch').fadeIn();
$('.q48 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q49 .b1').click(function(){swb145();Audio2();$('.q49 .b1').addClass('wbtr');
$('.q49 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q49 .b2,.q49 .b3').removeClass('wbtr');});
$('.q49 .b2').click(function(){swb146();Audio2();$('.q49 .b2').addClass('wbtr');
$('.q49 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q49 .b1,.q49 .b3').removeClass('wbtr');});
$('.q49 .b3').click(function(){swb147();Audio2();$('.q49 .b3').addClass('wbtr');
$('.q49 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q49 .b2,.q49 .b1').removeClass('wbtr');});
$('.q49 .c1').click(function(){scb49();Audio1();$('.q49 ww').hide();$('.q49 ch').fadeIn();
$('.q49 cm').slideDown('fast').delay(900).fadeOut();});    

$('.q50 .b1').click(function(){swb148();Audio2();$('.q50 .b1').addClass('wbtr');
$('.q50 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q50 .b2,.q50 .b3').removeClass('wbtr');});
$('.q50 .b2').click(function(){swb149();Audio2();$('.q50 .b2').addClass('wbtr');
$('.q50 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q50 .b1,.q50 .b3').removeClass('wbtr');});
$('.q50 .b3').click(function(){swb150();Audio2();$('.q50 .b3').addClass('wbtr');
$('.q50 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q50 .b2,.q50 .b1').removeClass('wbtr');});
$('.q50 .c1').click(function(){scb50();Audio1();$('.q50 ww').hide();$('.q50 ch').fadeIn();
$('.q50 cm').slideDown('fast').delay(900).fadeOut();});    

$('.q51 .b1').click(function(){swb151();Audio2();$('.q51 .b1').addClass('wbtr');
$('.q51 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q51 .b2,.q51 .b3').removeClass('wbtr');});
$('.q51 .b2').click(function(){swb152();Audio2();$('.q51 .b2').addClass('wbtr');
$('.q51 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q51 .b1,.q51 .b3').removeClass('wbtr');});
$('.q51 .b3').click(function(){swb153();Audio2();$('.q51 .b3').addClass('wbtr');
$('.q51 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q51 .b2,.q51 .b1').removeClass('wbtr');});
$('.q51 .c1').click(function(){scb51();Audio1();$('.q51 ww').hide();$('.q51 ch').fadeIn();
$('.q51 cm').slideDown('fast').delay(900).fadeOut();});    

$('.q52 .b1').click(function(){swb154();Audio2();$('.q52 .b1').addClass('wbtr');
$('.q52 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q52 .b2,.q52 .b3').removeClass('wbtr');});
$('.q52 .b2').click(function(){swb155();Audio2();$('.q52 .b2').addClass('wbtr');
$('.q52 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q52 .b1,.q52 .b3').removeClass('wbtr');});
$('.q52 .b3').click(function(){swb156();Audio2();$('.q52 .b3').addClass('wbtr');
$('.q52 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q52 .b2,.q52 .b1').removeClass('wbtr');});
$('.q52 .c1').click(function(){scb52();Audio1();$('.q52 ww').hide();$('.q52 ch').fadeIn();
$('.q52 cm').slideDown('fast').delay(900).fadeOut();});   

$('.q53 .b1').click(function(){swb157();Audio2();$('.q53 .b1').addClass('wbtr');
$('.q53 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q53 .b2,.q53 .b3').removeClass('wbtr');});
$('.q53 .b2').click(function(){swb158();Audio2();$('.q53 .b2').addClass('wbtr');
$('.q53 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q53 .b1,.q53 .b3').removeClass('wbtr');});
$('.q53 .b3').click(function(){swb159();Audio2();$('.q53 .b3').addClass('wbtr');
$('.q53 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q53 .b2,.q53 .b1').removeClass('wbtr');});
$('.q53 .c1').click(function(){scb53();Audio1();$('.q53 ww').hide();$('.q53 ch').fadeIn();
$('.q53 cm').slideDown('fast').delay(900).fadeOut();});

$('.q54 .b1').click(function(){swb160();Audio2();$('.q54 .b1').addClass('wbtr');
$('.q54 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q54 .b2,.q54 .b3').removeClass('wbtr');});
$('.q54 .b2').click(function(){swb161();Audio2();$('.q54 .b2').addClass('wbtr');
$('.q54 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q54 .b1,.q54 .b3').removeClass('wbtr');});
$('.q54 .b3').click(function(){swb162();Audio2();$('.q54 .b3').addClass('wbtr');
$('.q54 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q54 .b2,.q54 .b1').removeClass('wbtr');});
$('.q54 .c1').click(function(){scb54();Audio1();$('.q54 ww').hide();$('.q54 ch').fadeIn();
$('.q54 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q55 .b1').click(function(){swb163();Audio2();$('.q55 .b1').addClass('wbtr');
$('.q55 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q55 .b2,.q55 .b3').removeClass('wbtr');});
$('.q55 .b2').click(function(){swb164();Audio2();$('.q55 .b2').addClass('wbtr');
$('.q55 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q55 .b1,.q55 .b3').removeClass('wbtr');});
$('.q55 .b3').click(function(){swb165();Audio2();$('.q55 .b3').addClass('wbtr');
$('.q55 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q55 .b2,.q55 .b1').removeClass('wbtr');});
$('.q55 .c1').click(function(){scb55();Audio1();$('.q55 ww').hide();$('.q55 ch').fadeIn();
$('.q55 cm').slideDown('fast').delay(900).fadeOut();});   
    
$('.q56 .b1').click(function(){swb166();Audio2();$('.q56 .b1').addClass('wbtr');
$('.q56 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q56 .b2,.q56 .b3').removeClass('wbtr');});
$('.q56 .b2').click(function(){swb167();Audio2();$('.q56 .b2').addClass('wbtr');
$('.q56 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q56 .b1,.q56 .b3').removeClass('wbtr');});
$('.q56 .b3').click(function(){swb168();Audio2();$('.q56 .b3').addClass('wbtr');
$('.q56 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q56 .b2,.q56 .b1').removeClass('wbtr');});
$('.q56 .c1').click(function(){scb56();Audio1();$('.q56 ww').hide();$('.q56 ch').fadeIn();
$('.q56 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q57 .b1').click(function(){swb169();Audio2();$('.q57 .b1').addClass('wbtr');
$('.q57 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q57 .b2,.q57 .b3').removeClass('wbtr');});
$('.q57 .b2').click(function(){swb170();Audio2();$('.q57 .b2').addClass('wbtr');
$('.q57 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q57 .b1,.q57 .b3').removeClass('wbtr');});
$('.q57 .b3').click(function(){swb171();Audio2();$('.q57 .b3').addClass('wbtr');
$('.q57 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q57 .b2,.q57 .b1').removeClass('wbtr');});
$('.q57 .c1').click(function(){scb57();Audio1();$('.q57 ww').hide();$('.q57 ch').fadeIn();
$('.q57 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q58 .b1').click(function(){swb172();Audio2();$('.q58 .b1').addClass('wbtr');
$('.q58 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q58 .b2,.q58 .b3').removeClass('wbtr');});
$('.q58 .b2').click(function(){swb173();Audio2();$('.q58 .b2').addClass('wbtr');
$('.q58 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q58 .b1,.q58 .b3').removeClass('wbtr');});
$('.q58 .b3').click(function(){swb174();Audio2();$('.q58 .b3').addClass('wbtr');
$('.q58 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q58 .b2,.q58 .b1').removeClass('wbtr');});
$('.q58 .c1').click(function(){scb58();Audio1();$('.q58 ww').hide();$('.q58 ch').fadeIn();
$('.q58 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q59 .b1').click(function(){swb175();Audio2();$('.q59 .b1').addClass('wbtr');
$('.q59 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q59 .b2,.q59 .b3').removeClass('wbtr');});
$('.q59 .b2').click(function(){swb176();Audio2();$('.q59 .b2').addClass('wbtr');
$('.q59 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q59 .b1,.q59 .b3').removeClass('wbtr');});
$('.q59 .b3').click(function(){swb177();Audio2();$('.q59 .b3').addClass('wbtr');
$('.q59 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q59 .b2,.q59 .b1').removeClass('wbtr');});
$('.q59 .c1').click(function(){scb59();Audio1();$('.q59 ww').hide();$('.q59 ch').fadeIn();
$('.q59 cm').slideDown('fast').delay(900).fadeOut();});    
    
$('.q60 .b1').click(function(){swb178();Audio2();$('.q60 .b1').addClass('wbtr');
$('.q60 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q60 .b2,.q60 .b3').removeClass('wbtr');});
$('.q60 .b2').click(function(){swb179();Audio2();$('.q60 .b2').addClass('wbtr');
$('.q60 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q60 .b1,.q60 .b3').removeClass('wbtr');});
$('.q60 .b3').click(function(){swb180();Audio2();$('.q60 .b3').addClass('wbtr');
$('.q60 wm').finish().hide().slideDown('fast').delay(900).fadeOut();$('.q60 .b2,.q60 .b1').removeClass('wbtr');});
$('.q60 .c1').click(function(){scb60();Audio1();$('.q60 ww').hide();$('.q60 ch').fadeIn();
$('.q60 cm').slideDown('fast').delay(900).fadeOut();});    
    
    
});
  